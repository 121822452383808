import React from "react"
import FormInput from "../../Elements/Form/FormInput"
import Section from "../../Elements/Section"

const ReferralInformation = () => {
  return (
    <Section title="Referral" id="referral-information">
      <FormInput
        label="Full Name"
        helper="Please provide the full name of the Medical Representative or Product Specialist who referred you to the program."
        name="referral.fullName"
        placeholder="Juan Dela Cruz Jr."
        isRequired
      />
    </Section>
  )
}

export default ReferralInformation
