import React, { Fragment } from "react"
import FormInput from "../Elements/Form/FormInput"

const PersonalInformation = ({ isEmailDisabled }) => (
  <Fragment>
    <FormInput
      label="First Name"
      name="firstName"
      placeholder="Juan"
      isRequired
    />
    <div className="columns m-0 p-0">
      <div className="column m-0 p-0 mr-1 mr-0-mobile">
        <FormInput
          label="Last Name"
          placeholder="Dela Cruz Jr."
          type="text"
          name="lastName"
          isRequired
        />
      </div>
    </div>
    <FormInput
      label="Email"
      placeholder="jdelacruz@email.com"
      type="email"
      name="emailAddress"
      isRequired
      disabled={isEmailDisabled}
    />
    <FormInput
      label="Mobile Number"
      placeholder="09991234567"
      type="tel"
      pattern="[0,9]{2}[0-9]{9}"
      name="mobileNumber"
      maxLength={11}
      isRequired
      isNumeric
    />
  </Fragment>
)

export default PersonalInformation
