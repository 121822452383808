import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import ActionButtons from "../Elements/ActionButtons"
import InvalidFormMessage from "../Elements/InvalidFormMessage"
import ClinicsInformation from "./DoctorEnrollment/ClinicsInformation"
import ReferralInformation from "./DoctorEnrollment/ReferralInformation"
import DoctorPersonalInformation from "./DoctorEnrollment/DoctorPersonalInformation"

import { useSession } from "../../services/hooks/useSession"
import { AppContext } from "../../context/AppContext"
import { doctorEnrollmentValidationSchema } from "./utils/formData"

const DoctorEnrollment = () => {
  const { state, dispatch } = useContext(AppContext)
  const { doctorEnrollment } = state
  const {
    sessionFormValues,
    isFormValuesEmpty,
    initializeFormValues,
    handleFormChange,
    clearFormValues,
  } = useSession("doctorEnrollment")

  useEffect(() => {
    initializeFormValues()
  }, [])

  const handleSubmit = async (values) => {
    // Comment this out if you want to continue testing out form submissions
    clearFormValues()

    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        doctorEnrollment: {
          ...doctorEnrollment,
          ...values,
        },
      },
    })
    navigate("/enroll-doctor/summary")
  }

  const handleSpecializationChange = (config) => {
    let { setFieldValue, specialization } = config
    setFieldValue("otherSpecialization", specialization === "Other" ? "" : " ")
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={
        isFormValuesEmpty ? { ...doctorEnrollment } : { ...sessionFormValues }
      }
      validationSchema={doctorEnrollmentValidationSchema}
      enableReinitialize
    >
      {({ values, isValid, submitCount, setFieldValue }) => (
        <Form onChange={() => handleFormChange(values)}>
          <DoctorPersonalInformation
            values={values}
            handleSpecializationChange={(specialization) =>
              handleSpecializationChange({
                setFieldValue,
                specialization: specialization?.value,
              })
            }
          />
          <ClinicsInformation values={values} />
          <ReferralInformation />
          {!isValid && submitCount > 0 && (
            <InvalidFormMessage color="danger" name="missingRequiredFields" />
          )}
          <ActionButtons submit={{ label: "Next" }} />
        </Form>
      )}
    </Formik>
  )
}

export default DoctorEnrollment
